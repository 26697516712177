<template>
  <div>
    <div
      v-show="selectLoad"
      class="pt-3"
    >
      <div class="kt-spinner kt-spinner--v2 kt-spinner--md kt-spinner--brand"></div>
    </div>
    <template v-if="checkPermission('WorkOrderResource.POST.WorkOrder')">
      <div v-show="!selectLoad">
        <select
          name="jadwal"
          class="form-control bootstrap-select selected_pic"
          v-bind:id="idSelectPic"
          data-live-search="true"
          data-size=5
          v-model="pic_selected"
          :value="selectedValue"
          @change="changePIC()"
          style="height: 40px"
          :disabled="!checkPermission('WorkOrderResource.PUT.WorkOrder')"
        >
          <option
            v-for="item in picList"
            :value="item.id"
            :key="item.id"
          >{{ item.fullname }}</option>
        </select>
      </div>
    </template>
  </div>
</template>

<script>
import EventBus from "../../../eventBus";
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PicPharmacyRepository = RepositoryFactory.get("picPharmacy");
const PharmacyRepository = RepositoryFactory.get("pharmacy");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      pic_selected: null,
      selectLoad: true
    };
  },
  props: {
    idSelectPic: { type: String },
    pic: { type: Number, default: null },
    queueId: { type: Number },
    workOrderId: { type: Number },
    statusBoard: { type: String }
  },
  computed: {
    selectedValue() {
      this.pic_selected = this.pic;
    },
    ...mapState('PharmacyPicStore', {
      picList: state => state.picList
    }),
  },
  watch: {
    picList: function() {
      this.getPIC();
    }
  },
  methods: {
    async getPIC() {
      this.selectLoad = false;

      $("#" + this.idSelectPic).selectpicker({
        width: "100%"
      });

      this.$nextTick(() => {
        $("#" + this.idSelectPic).selectpicker("refresh");
      });
    },
    showToastError: function (message) {
      toastr.error(message);
    },
    changePIC: function () {
      var vx = this;

      this.selectLoad = true;

      if (this.statusBoard == "TODO" && !this.workOrderId) {
        var params = {
          queue: {
            id: this.queueId
          },
          status: this.statusBoard,
          workOrderPICId: this.pic_selected
        };

        this.postWorkOrder(params);
      } else {
        var params = {
          id: this.workOrderId,
          workOrderPICId: this.pic_selected
        };

        this.putWorkOrder(params);
      }
    },
    async postWorkOrder(params) {
      var vx = this;

      await PharmacyRepository.postWorkOrder(params)
        .then(() => {
          vx.selectLoad = false;
          this.$emit("update:changedValue", this.pic_selected);
          this.$emit("event");
          // this.$emit("event", response.data.id, this.queueId, 'IN_PROGRESS', this.pic_selected);
        })
        .catch(error => {
          toastr.error("Tidak dapat mengubah PIC, silahkan coba lagi.");
        });
    },
    async putWorkOrder(params) {
      var vx = this;

      await PharmacyRepository.putWorkOrder(params)
        .then(response => {
          vx.selectLoad = false;
          this.$emit("update:changedValue", this.pic_selected);
          this.$emit("event");
        })
        .catch(error => {
          toastr.error("Tidak dapat mengubah PIC, silahkan coba lagi.");
        });
    },
    blockSelect: function () {
      KTApp.block("#" + this.idSelectPic, {
        overlayColor: "#f8f9fa",
        opacity: 1,
        type: "v2",
        state: "primary",
        size: "lg"
      });
    },
    unblockSelect: function () {
      KTApp.unblock("#" + this.idSelectPic);
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
    this.getPIC();
  }
};
</script>
