<template>
  <div
    class="kt-portlet bg-light px-4"
    :id="kanbanContainer"
  >
    <div class="d-flex justify-content-between">
      <h6
        class="text-uppercase my-4"
        style="z-index:30"
      >{{ kanbanTitle }}</h6>
      <span
        class="btn btn-label-info rounded-0 mb-3 font-weight-bold"
        style="z-index:30"
      >{{ kanbanCardTotal }}</span>
    </div>
    <div
      class="ui-sortable kanban_board kt-scroll" :class="'ps-scroll-' + kanbanId"
      :id="kanbanId"
      data-scroll="true"
      style="height: 550px;"
    >
    <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    kanbanContainer: { type: String },
    kanbanTitle: { type: String },
    kanbanCardTotal: { type: Number },
    kanbanId: { type: String }
  }
}
</script>