export default class BlockBoard {
    blockBoard(field, opacity) {
        KTApp.block(field, {
            overlayColor: '#f8f9fa',
            opacity: opacity,
            type: 'v2',
            state: 'primary',
            size: 'lg'
        });
    }

    unblockBoard(field) {
        KTApp.unblock(field);
    }

    disableUi(prevBoard, selectedPIC) {
        if (prevBoard == 'todo') {
            $('#done_container')
                .removeClass('bg-light')
                .addClass('bg-disabled');
        }

        if (prevBoard == 'done') {
            $('#to_do_container')
                .removeClass('bg-light')
                .addClass('bg-disabled');
        }

        if (!selectedPIC) {
            $('#in_progress_container')
                .removeClass('bg-light')
                .addClass('bg-disabled');
        }
    }

    enableUi() {
        $('#to_do_container')
            .removeClass('bg-disabled')
            .addClass('bg-light');
        $('#in_progress_container')
            .removeClass('bg-disabled')
            .addClass('bg-light');
        $('#done_container')
            .removeClass('bg-disabled')
            .addClass('bg-light');
    }
}
