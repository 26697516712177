<template>
  <div>
    <base-header base-title="Farmasi"></base-header>
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="row pb-4">
        <div class="col-md-6">
          <div>
            <label
              class="btn btn-outline-primary btn-elevate btn-pill btn-sm"
              v-bind:class="{ active: filterCard == 1 }"
            >
              <input
                style="display:none;"
                v-model="filterCard"
                id="one"
                value="1"
                type="radio"
                checked
              /> Semua Jenis
            </label>
            <label
              class="btn btn-outline-success btn-elevate btn-pill btn-sm"
              v-bind:class="{ active: filterCard == 2 }"
            >
              <input
                style="display:none;"
                v-model="filterCard"
                id="two"
                value="2"
                type="radio"
              /> Farmasi Racikan
            </label>
            <label
              class="btn btn-outline-danger btn-elevate btn-pill btn-sm"
              v-bind:class="{ active: filterCard == 3 }"
            >
              <input
                style="display:none;"
                v-model="filterCard"
                id="three"
                value="3"
                type="radio"
              /> Farmasi Non Racikan
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="float-right">
            <auto-refresh
              v-if="autoRefreshState"
              table-name=""
              :on-refresh="setFarmasiBoard"
              :auto-refresh-state="autoRefreshState"
            ></auto-refresh>
          </div>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-4">
          <kanban-board
            kanban-container="to_do_container"
            kanban-title="To Do"
            kanban-id="todo"
            :kanban-card-total="to_do_count"
          >
            <div
              v-for="item in dataToDo"
              :key="item.id"
            >
              <kanban-card
                :work-order-id="item.id"
                :queue-id="item.queue.id"
                :card-id="item.queue.queueNoFormatted"
                :card-badge="item.queue.queueUnit.displayName2"
                :card-badge-id="item.queue.queueUnit.id"
                :card-time-first="item.firstToDoLocalDateTime ? item.firstToDoLocalDateTime : item.queue.lastModifiedDateTime"
                :card-time-second="null"
                :enable-timer="false"
                :text-timer-first="item.firstToDoLocalDateTime ? item.firstToDoLocalDateTime : item.queue.lastModifiedDateTime"
              >
                <template v-slot:moveCardTo>
                  <button
                    class="btn btn-upper btn-sm btn-bold btn-secondary"
                    :disabled="!item.workOrderPICId"
                    @click="moveCard(item.id, item.queue.id, 'IN_PROGRESS', item.workOrderPICId)"
                  ><i class="flaticon2-fast-next"></i>In Progress</button>
                </template>
                <template v-slot:pic>
                  <select-pic
                    v-bind:id-select-pic="'pic_' + item.queue.id"
                    v-bind:pic="item.workOrderPICId"
                    v-bind:queue-id="item.queue.id"
                    :work-order-id="item.id"
                    status-board="TODO"
                    :changedValue.sync="selectedPic"
                    @event="setFarmasiBoard"
                  ></select-pic>
                </template>
              </kanban-card>
            </div>
          </kanban-board>
        </div>
        <div class="col-4">
          <kanban-board
            kanban-container="in_progress_container"
            kanban-title="In Progress"
            kanban-id="in_progress"
            :kanban-card-total="in_progress_count"
          >
            <div
              v-for="item in dataInProgress"
              :key="item.id"
            >
              <kanban-card
                :work-order-id="item.id"
                :queue-id="item.queue.id"
                :card-id="item.queue.queueNoFormatted"
                :card-badge="item.queue.queueUnit.displayName2"
                :card-badge-id="item.queue.queueUnit.id"
                :card-time-first="item.lastInProgressLocalDateTime"
                :card-time-second="null"
                :enable-timer="true"
                :text-timer-first="item.firstToDoLocalDateTime ? item.firstToDoLocalDateTime : item.queue.lastModifiedDateTime"
              >
                <template v-slot:moveCardTo>
                  <button
                    class="btn btn-upper btn-sm btn-bold btn-secondary"
                    @click="moveCard(item.id, item.queue.id, 'TODO', item.workOrderPICId)"
                  ><i class="flaticon2-fast-back"></i>To Do</button>
                  <button
                    class="btn btn-upper btn-sm btn-bold btn-secondary"
                    @click="moveCard(item.id, item.queue.id, 'DONE', item.workOrderPICId)"
                  ><i class="flaticon2-fast-next"></i>Done</button>
                </template>
                <template v-slot:pic>
                  <select-pic
                    v-bind:id-select-pic="'pic_' + item.queue.id"
                    v-bind:pic="item.workOrderPICId"
                    v-bind:queue-id="item.queue.id"
                    :work-order-id="item.id"
                    status-board="IN_PROGRESS"
                    :changedValue.sync="selectedPic"
                    @event="setFarmasiBoard"
                  ></select-pic>
                </template>
              </kanban-card>
            </div>
          </kanban-board>
        </div>
        <div class="col-4">
          <kanban-board
            kanban-container="done_container"
            kanban-title="Done"
            kanban-id="done"
            :kanban-card-total="done_count"
          >
            <div
              v-for="item in dataDone"
              :key="item.id"
            >
              <kanban-card
                :work-order-id="item.id"
                :queue-id="item.queue.id"
                :card-id="item.queue.queueNoFormatted"
                :card-badge="item.queue.queueUnit.displayName2"
                :card-badge-id="item.queue.queueUnit.id"
                :card-time-first="item.lastInProgressLocalDateTime"
                :card-time-second="item.lastModifiedDateTime"
                :enable-timer="true"
                :text-timer-first="item.firstToDoLocalDateTime ? item.firstToDoLocalDateTime : item.queue.lastModifiedDateTime"
              >
                <template v-slot:moveCardTo>
                  <button
                    class="btn btn-upper btn-sm btn-bold btn-secondary"
                    @click="moveCard(item.id, item.queue.id, 'IN_PROGRESS', item.workOrderPICId)"
                  ><i class="flaticon2-fast-back"></i>In Progress</button>
                </template>
                <template v-slot:pic>
                  <select-pic
                    v-bind:id-select-pic="'pic_' + item.queue.id"
                    v-bind:pic="item.workOrderPICId"
                    v-bind:queue-id="item.queue.id"
                    :work-order-id="item.id"
                    status-board="DONE"
                    :changedValue.sync="selectedPic"
                    @event="setFarmasiBoard"
                  ></select-pic>
                </template>
              </kanban-card>
            </div>
          </kanban-board>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoRefresh from "../_general/AutoRefresh";
import BaseHeader from "./../_base/BaseHeader";
import KanbanBoard from "./_components/KanbanBoard";
import KanbanCard from "./_components/KanbanCard";
import SelectPic from "./_components/SelectPic";
import { mapState, mapMutations, mapActions } from 'vuex';
import BlockBoard from "../_js/BlockBoard";
const blockBoard = new BlockBoard();

export default {
  components: {
    AutoRefresh, BaseHeader, KanbanBoard, KanbanCard, SelectPic
  },
  data() {
    return {
      filterCard: 1,
      selectedPic: null
    }
  },
  watch: {
    filterCard: function (val) {
      this.blockUnblockBoard(0.5, "PharmacyStore/filteringCard", val);
    }
  },
  computed: {
    ...mapState('PharmacyStore', {
      maxlastModifiedDate: state => state.maxlastModifiedDate,
      dataToDo: state => state.dataToDo,
      dataInProgress: state => state.dataInProgress,
      dataDone: state => state.dataDone,
      autoRefreshState: state => state.autoRefreshState,
      firstLoad: state => state.firstLoad
    }),
    to_do_count() {
      return this.dataToDo.length;
    },
    in_progress_count() {
      return this.dataInProgress.length;
    },
    done_count() {
      return this.dataDone.length;
    }
  },
  methods: {
    ...mapActions({
      getPicFarmasiList: 'PharmacyPicStore/getPicFarmasiList'
    }),
    setFarmasiBoard: function () {
      this.$store.dispatch("PharmacyStore/setPharmacyBoard").then(() => {
        $("select").selectpicker("refresh");
      });
      this.getPicFarmasiList();
    },
    blockUnblockBoard: function (opacity, action, val) {
      blockBoard.blockBoard(
        "#to_do_container, #in_progress_container, #done_container", opacity
      );
      this.$store.dispatch(action, val).then(() => {
        blockBoard.unblockBoard(
          "#to_do_container, #in_progress_container, #done_container"
        );
      });
    },
    moveCard: function (workOrderId, queueId, status, picId) {
      if (!workOrderId && picId) {
        var params = {
          queue: {
            id: queueId
          },
          status: status.toUpperCase(),
          workOrderPICId: picId
        };

        this.blockUnblockBoard(0.5, "PharmacyStore/postCard", params);
      }
      else if (picId) {
        var params = {
          id: workOrderId,
          status: status.toUpperCase()
        };

        this.blockUnblockBoard(0.5, "PharmacyStore/putCard", params);
      }
    }
  },
  mounted() {
    this.setFarmasiBoard();

    const ps1 = new PerfectScrollbar(".ps-scroll-todo");
    const ps2 = new PerfectScrollbar(".ps-scroll-in_progress");
    const ps3 = new PerfectScrollbar(".ps-scroll-done");
  }
}
</script>