<template>
  <div
    v-bind:id="queueId"
    class="kt-portlet-card bg-white kt-portlet--sortable mb-3"
    style="box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);"
  >
    <div class="kt-portlet__head px-4">
      <div class="kt-portlet__head-label">
        <div class="kt-heading kt-heading--lg kt-heading--bold"># {{ cardId }}</div>
      </div>
      <div class="kt-portlet__head-toolbar">
        <div class="kt-portlet__head-actions">
          <slot name="moveCardTo"></slot>
        </div>
      </div>
    </div>
    <div class="kt-portlet__body p-4">
      <div class="kt-portlet__content">
        <div class="d-flex justify-content-between">
          <input
            class="work_order_id"
            type="hidden"
            v-bind:value="workOrderId"
          >
          <p class="text-muted">
            <i class="fas fa-clock text-secondary"></i>
            {{ textTimerFirst | hourMinutes }}
            <span class="mx-2">&bull;</span>
            <text-timer
              v-bind:first="textTimerFirst"
              v-bind:second="cardTimeSecond"
            ></text-timer>
          </p>
          <input
            class="queue_id"
            type="hidden"
            v-bind:value="queueId"
          >
          <div v-if="cardBadgeId == env_nonracikan">
            <span class="badge badge-pill badge-danger font-weight-bold align-self-start">{{ cardBadge }}</span>
          </div>
          <div v-else-if="cardBadgeId == env_racikan">
            <span class="badge badge-pill badge-success font-weight-bold align-self-start">{{ cardBadge }}</span>
          </div>
        </div>
        <div class="row h-100">
          <div class="col-8">
            <slot name="pic"></slot>
          </div>
          <div class="col-4 my-auto">
            <span
              class="float-right font-weight-bold"
              v-if="enableTimer"
            >
              <clock-timer
                v-bind:first="cardTimeFirst"
                v-bind:second="cardTimeSecond"
                v-bind:identifier="'timer_' + cardId"
              ></clock-timer>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClockTimer from "../../_general/ClockTimer";
import TextTimer from "../../_general/TextTimer";

export default {
  data() {
    return {
      env_racikan: process.env.VUE_APP_PHARMACY_RACIKAN,
      env_nonracikan: process.env.VUE_APP_PHARMACY_RACIKAN
    }
  },
  components: {
    ClockTimer,
    TextTimer
  },
  props: [
    "workOrderId",
    "queueId",
    "cardId",
    "cardBadge",
    "cardBadgeId",
    "cardTimeFirst",
    "cardTimeSecond",
    "enableTimer",
    "textTimerFirst"
  ],
  filters: {
    hourMinutes: function (value) {
      var momentDate = moment(value);
      return momentDate.format("HH:mm");
    }
  }
};
</script>

