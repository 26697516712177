<template>
  <span>{{ timer }}</span>
</template>

<script>
export default {
  data() {
    return {
      timer: null
    };
  },
  props: {
    first: { type: String },
    second: { type: String }
  },
  methods: {
    updateWaitingTime(start, end, elapse) {
      this.timer = end.from(start, elapse);
    }
  },
  mounted() {
    var first = moment(this.first);
    var second = moment(this.second);

    this.updateWaitingTime(moment(), first, false);
    
     if(this.second == null) {
        setInterval(() => this.updateWaitingTime(moment(), first, false), 1 * 60000);
      }
      else {
        this.updateWaitingTime(second, first, true);
      }
  }
};
</script>
