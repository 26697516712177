<template>
  <span v-bind:id="identifier">{{ timer }}</span>
</template>

<script>
export default {
  data() {
    return {
      timer: null
    };
  },
  props: {
    first: { type: String },
    second: { type: String },
    identifier: { type: String }
  },
  methods: {
    updateCurrentTime(start, end) {
      var elapsed = moment
        .utc(
          moment(start, "YYYY-MM-DD HH:mm:ss").diff(
            moment(end, "YYYY-MM-DD HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      this.timer = elapsed;
    },
    getClockTimer: function() {

      if(this.second == null) {
        setInterval(() => this.updateCurrentTime(moment(), this.first), 1 * 1000);
      }
      else {
        this.updateCurrentTime(this.second, this.first);
      }
    }
  },
  mounted() {
    this.getClockTimer();
  }
};
</script>
